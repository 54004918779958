import Lottie from 'react-lottie-player';

import PESLoading from 'app/assets/animations/pes-loading.json';

interface LoadingBubblesProps {
  size?: number;
  color?: string;
}

export const LoadingBubbles = ({
  size = 350,
  color = '#1B953E',
}: LoadingBubblesProps) => {
  return (
    <div>
      <Lottie
        loop
        animationData={PESLoading}
        play
        style={{ width: size, height: size, fill: color }}
      />
    </div>
  );
};

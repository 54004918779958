import { DropdownMenuItem } from 'app/components/DropdownMenu';
import { HeaderMenuItem } from 'app/components/layout/HeaderMenu';
import { CLIENT_NAME, CURRENT_ENV, VERSION_NUMBER } from 'app/config';

import {
  CUSTOMER_PAGES_ACCESS_PERMISSIONS,
  INVOICE_PAGES_ACCESS_PERMISSIONS,
  PRODUCT_PAGES_ACCESS_PERMISSIONS,
  QUOTATION_PAGE_PERMISSIONS,
  RECEIPT_PAGES_ACCESS_PERMISSIONS,
  REPORT_PAGES_ACCESS_PERMISSIONS,
  STAFF_PAGES_ACCESS_PERMISSION,
  TEMPLATE_PAGES_ACCESS_PERMISSIONS,
  USER_PAGES_ACCESS_PERMISSIONS,
} from './permission';
import { ROUTES } from './routes';

export const HEADER_CONTENT: HeaderMenuItem[] = [
  {
    title: '客戶列表',
    href: ROUTES.CUSTOMERS,
    permissions: CUSTOMER_PAGES_ACCESS_PERMISSIONS,
  },
  { title: '報價單', href: ROUTES.QUOTATIONS, permissions: QUOTATION_PAGE_PERMISSIONS },
  {
    title: '發票',
    href: ROUTES.INVOICES,
    permissions: INVOICE_PAGES_ACCESS_PERMISSIONS,
  },
  {
    title: '貨品管理',
    href: ROUTES.PRODUCTS,
    permissions: PRODUCT_PAGES_ACCESS_PERMISSIONS,
  },
  {
    title: '收款紀錄',
    href: ROUTES.RECEIPTS,
    permissions: RECEIPT_PAGES_ACCESS_PERMISSIONS,
  },
  {
    title: '員工管理',
    href: ROUTES.COMMISSIONS,
    permissions: STAFF_PAGES_ACCESS_PERMISSION,
    subMenu: [
      { title: '司機列表', href: ROUTES.DRIVERS },
      { title: '工作記錄', href: ROUTES.COMMISSIONS },
    ],
  },
  {
    title: '範本',
    href: ROUTES.TEMPLATES,
    permissions: TEMPLATE_PAGES_ACCESS_PERMISSIONS,
  },
  { title: '數據報表', href: ROUTES.REPORTS, permissions: REPORT_PAGES_ACCESS_PERMISSIONS },
  { title: '用戶管理', href: ROUTES.USERS, permissions: USER_PAGES_ACCESS_PERMISSIONS },
];

export const PROFILE_DROPDOWN_ITEMS: DropdownMenuItem[] = [
  {
    title: '登出',
    href: '/logout',
    className: 'text-text-red',
  },
  {
    title: `版本: ${CLIENT_NAME}-${CURRENT_ENV}-${VERSION_NUMBER}`,
    href: '#',
  },
];

//remember to change the variable in variable.scss as well when changing this
export const HEADER_HEIGHT = 62;

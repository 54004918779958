import * as Yup from 'yup';

import { BreadcrumbItem } from 'app/components/common/Breadcrumb';
import { OptionItems, UserRole } from 'app/models';
import { CreateUserParams } from 'app/services/UserServices';

import { ROUTES } from './routes';

export const NEW_USER_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '用戶管理 ',
    href: ROUTES.USERS,
  },
  {
    active: true,
    title: '新增用戶資料',
    href: '#',
  },
];

export const EDIT_USER_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '用戶管理 ',
    href: ROUTES.USERS,
  },
  {
    active: true,
    title: '編輯用戶資料',
    href: '#',
  },
];

export const USER_FORM: CreateUserParams = {
  userName: '',
  password: '',
  role: UserRole.warehouse,
  displayName: '',
};

export const USER_FORM_SCHEMA = Yup.object().shape({
  userName: Yup.string()
    .min(1, '最少一個字元')
    .max(200, '最多200個字元')
    .required('必填項目'),
  password: Yup.string()
    .min(1, '最少一個字元')
    .max(200, '最多200個字元')
    .required('必填項目'),
  role: Yup.string().required('必填項目'),
});

export const USER_EDIT_FORM_SCHEMA = Yup.object().shape({
  userName: Yup.string()
    .min(1, '最少一個字元')
    .max(200, '最多200個字元')
    .required('必填項目'),
  role: Yup.string().required('必填項目'),
});

export const ORDER_ROLE_OPTIONS: OptionItems[] = [
  { value: 'sales', label: '銷售部' },
  { value: 'order', label: '柯打部' },
  { value: 'account', label: '會計部' },
  { value: 'warehouse', label: '倉庫部' },
];

export const ADMIN_ROLE_OPTIONS: OptionItems[] = [
  { value: 'admin', label: '管理層' },
  { value: 'sales', label: '銷售部' },
  { value: 'order', label: '柯打部' },
  { value: 'account', label: '會計部' },
  { value: 'warehouse', label: '倉庫部' },
];

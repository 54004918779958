import * as Yup from 'yup';

import { BreadcrumbItem } from 'app/components/common/Breadcrumb';
import { CLIENT_NAME } from 'app/config';
import { translatePaymentTerms } from 'app/helpers';
import {
  CustomerData,
  CustomerDataContact,
  CustomerPaymentTerm,
  OptionItems,
} from 'app/models';

import { ROUTES } from './routes';

export const NEW_CUSTOMER_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '客戶列表',
    href: ROUTES.CUSTOMERS,
  },
  {
    active: true,
    title: '新增客戶資料',
    href: '#',
  },
];

export const EDIT_CUSTOMER_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '客戶列表',
    href: ROUTES.CUSTOMERS,
  },
  {
    active: true,
    title: '編輯客戶資料',
    href: '#',
  },
];

export const CUSTOMER_PAYMENT_TERM_OPTIONS: OptionItems[] = [
  {
    label: translatePaymentTerms(CustomerPaymentTerm.MONTHS_NUMBER),
    value: CustomerPaymentTerm.MONTHS_NUMBER,
  },
  {
    label: translatePaymentTerms(CustomerPaymentTerm.DAYS_NUMBER),
    value: CustomerPaymentTerm.DAYS_NUMBER,
  },
  {
    label: translatePaymentTerms(
      CustomerPaymentTerm.MONTH_END_WITH_MONTHS_NUMBER
    ),
    value: CustomerPaymentTerm.MONTH_END_WITH_MONTHS_NUMBER,
  },
  {
    label: translatePaymentTerms(
      CustomerPaymentTerm.MONTH_END_WITH_DAYS_NUMBER
    ),
    value: CustomerPaymentTerm.MONTH_END_WITH_DAYS_NUMBER,
  },
];

export const CUSTOMER_FORM_ITEM: CustomerData = {
  customerRef: '',
  customerName: '',
  customerChiName: '',
  fromCompany: CLIENT_NAME,
  projectName: '',
  projectChiName: '',
  city: null,
  district: '',
  street: '',
  building: '',
  phone: '',
  fax: '',
  remarks: '',
  remarks1: '',
  remarks2: '',
  Contacts: [],
  paymentTerm: null,
  paymentTermValue: null,
  billingAgreement: '',
};

export const CUSTOMER_FORM_SCHEMA = Yup.object().shape({
  customerRef: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(20, '最多20個字元')
    .matches(/^([A-Z])(\d{3})$|^([A-Z])(\d{3})-(\d{3})$/, '客戶編號格式有誤')
    .required('必填項目'),
  customerName: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元')
    .required('必填項目'),
  projectName: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  projectChiName: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  city: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  district: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  street: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  building: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  phone: Yup.string().min(1, '最少一個字元').nullable().max(20, '最多20個字元'),
  fax: Yup.string().min(1, '最少一個字元').nullable().max(20, '最多20個字元'),
  remarks: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  remarks1: Yup.string()
    .nullable()
    .max(200, '最多200個字元'),
  remarks2: Yup.string()
    .nullable()
    .max(200, '最多200個字元'),
  Contacts: Yup.array()
    .min(1, '最少輸入一個聯絡人')
    .of(
      Yup.object().shape({
        name: Yup.string()
          .min(1, '最少一個字元')
          .nullable()
          .required('必填項目'),
        department: Yup.string()
          .min(1, '最少一個字元')
          .nullable()
          .max(100, '最多100個字元'),
        phone: Yup.string()
          .min(1, '最少一個字元')
          .nullable(),
        mobile: Yup.string()
          .min(1, '最少一個字元')
          .nullable(),
        fax: Yup.string()
          .min(1, '最少一個字元')
          .nullable(),
        email: Yup.string()
          .min(1, '最少一個字元')
          .nullable()
          .max(200, '最多200個字元'),
        isMainContact: Yup.boolean().required('必填項目'),
      })
    )
    .test('main-contact', '最少設定一個主聯絡人', (values) => {
      let isValid = false;
      if (values?.length) {
        for (let i = 0; i < values.length; i++) {
          if (values[i].isMainContact) {
            isValid = true;
            break;
          }
        }
      } else {
        isValid = true;
      }
      return isValid;
    }),
});

export const CUSTOMER_CONTACT_FORM_ITEM: CustomerDataContact = {
  name: '',
  department: '',
  phone: '',
  mobile: '',
  fax: '',
  email: '',
  isMainContact: false,
};

export const CUSTOMER_CONTACT_FORM_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(20, '最多20個字元')
    .required('必填項目'),
  department: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(100, '最多100個字元'),
  phone: Yup.string().min(1, '最少一個字元').nullable().max(9, '最多9個字元'),
  mobile: Yup.string().min(1, '最少一個字元').nullable().max(9, '最多9個字元'),
  fax: Yup.string().min(1, '最少一個字元').nullable().max(9, '最多9個字元'),
  email: Yup.string()
    .email('電郵格式有誤')
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  isMainContact: Yup.boolean().required('必填項目'),
});

import { Product, ProductData } from 'app/models';

export const formatProductFormData = (product: Product): ProductData => {
  const tempFormData: ProductData = {
    productRef: product.productRef,
    name: product.name,
    type: product.type,
    description: product.description,
    unit: product.unit,
    unitPrice:  product.unitPrice,
    remarks: product.remarks,
    defaultCommission: product.defaultCommission,
  };

  return tempFormData;
};

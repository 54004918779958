import moment from 'moment';

export const parseDate = (dateStr: string, format: string) => {
  // format: DD/m
  const dateRegEx = /^\d{1,2}\/\d{1,2}$/;
  if (dateRegEx.test(dateStr)) {
    return moment(dateStr, 'D/M', true).toDate();
  }

  // format: YYYY/MM/DD
  const dateRegEx2 = /^\d{4}\/\d{1,2}\/\d{1,2}$/;
  if (dateRegEx2.test(dateStr)) {
    return moment(dateStr, 'YYYY/M/D', true).toDate();
  }

  // format: DD/MM/YYYY
  const dateRegEx3 = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  if (dateRegEx3.test(dateStr)) {
    return moment(dateStr, 'D/M/YYYY', true).toDate();
  }

  // format: DDMM
  const dateRegEx4 = /^\d{1,2}\d{1,2}$/;
  if (dateRegEx4.test(dateStr)) {
    return moment(dateStr, 'DDMM', true).toDate();
  }

  // format: DDMMYYYY
  const dateRegEx5 = /^\d{1,2}\d{1,2}\d{4}$/;
  if (dateRegEx5.test(dateStr)) {
    return moment(dateStr, 'DDMMYYYY', true).toDate();
  }

  // format: YYYY-MM-DD
  const dateRegEx6 = /^\d{4}-\d{1,2}-\d{1,2}$/;
  if (dateRegEx6.test(dateStr)) {
    return moment(dateStr, 'YYYY-MM-DD', true).toDate();
  }

  return moment(dateStr, format, true).toDate();
};

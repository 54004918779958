import { TimeStamp } from './Common';
import { User } from './User';

export interface CustomerDataContact {
  name: string;
  department: string;
  phone: string;
  mobile: string | null;
  fax: string;
  email: string;
  isMainContact: boolean;
}

// export type CustomerPaymentTermType =
//   | "MONTHS_NUMBER"
//   | "DAYS_NUMBER"
//   | "MONTH_END_WITH_MONTHS_NUMBER"
//   | "MONTH_END_WITH_DAYS_NUMBER";

export enum CustomerPaymentTerm {
  'MONTHS_NUMBER' = 'MONTHS_NUMBER',
  'DAYS_NUMBER' = 'DAYS_NUMBER',
  'MONTH_END_WITH_MONTHS_NUMBER' = 'MONTH_END_WITH_MONTHS_NUMBER',
  'MONTH_END_WITH_DAYS_NUMBER' = 'MONTH_END_WITH_DAYS_NUMBER',
}

export interface CustomerData {
  customerRef: string;
  customerName: string;
  customerChiName: string;
  fromCompany: string;
  projectName?: string | null;
  projectChiName?: string | null;
  city: string | null;
  district: string;
  street: string;
  building: string;
  phone: string;
  fax: string;
  remarks?: string | null;
  remarks1: string | null;
  remarks2: string | null;
  quotationAcceptedCount?: number | null;
  quotationInProgressCount?: number | null;
  Contacts: CustomerDataContact[];
  paymentTerm: CustomerPaymentTerm | null;
  paymentTermValue: number | null;
  billingAgreement: string | null;
}

export interface CustomerContact extends CustomerDataContact, TimeStamp {
  contactId: number;
  customerId: number;
}

export interface Customer extends Omit<CustomerData, 'Contacts'>, TimeStamp {
  customerId: number;
  userId: number;
  Contacts: CustomerContact[];
  User: User;
}

export interface QuotationCount {
  quotationAcceptedCount?: number | null;
  quotationInProgressCount?: number | null;
}

export interface FormattedCustomer extends TimeStamp {
  customerId: number;
  customerRef: string;
  customerName: string;
  customerChiName: string;
  fromCompany: string;
  projectName: string | null;
  projectChiName?: string | null;
  address: string | null;
  phone: string | null;
  fax: string | null;
  remarks: string | null;
  remarks1: string | null;
  remarks2: string | null;
  quotationStatus: QuotationCount;
  userId: number;
  mainContact: CustomerContact | null;
  User: User;
}

import moment from 'moment';
import * as Yup from 'yup';

import { BreadcrumbItem } from 'app/components/common/Breadcrumb';
import { CLIENT_NAME } from 'app/config';
import { OptionItems, FormQuotationData, FormQuotationItemData } from 'app/models';

import { ROUTES } from './routes';

export const NEW_QUOTATION_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '報價單列表',
    href: ROUTES.QUOTATIONS,
  },
  {
    active: true,
    title: '新增報價單',
    href: '#',
  },
];

export const EDIT_QUOTATION_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '報價單列表',
    href: ROUTES.QUOTATIONS,
  },
  {
    active: true,
    title: '編輯報價單',
    href: '#',
  },
];

export const PREVIEW_QUOTATION_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '報價單列表',
    href: ROUTES.QUOTATIONS,
  },
  {
    active: true,
    title: '預覽報價單',
    href: '#',
  },
];

export const QUOTATION_FORM_ITEM: FormQuotationData = {
  quotationRef: '',
  fromCompany: CLIENT_NAME,
  date: moment().toDate(),
  remarks: '',
  status: '草稿',
  reason: '',
  customerId: null,
  customerRef: '',
  customerName: '',
  projectName: '',
  district: '',
  street: '',
  building: '',
  city: '',
  contactId: null,
  contactName: '',
  contactDepartment: '',
  contactPhone: '',
  contactMobile: '',
  contactFax: '',
  contactEmail: '',
  QuotationItems: [],
  QuotationFootnoteItems: [
    { content: '', quotationId: '', itemOrder: 0 },
    { content: '', quotationId: '', itemOrder: 1 },
    { content: '', quotationId: '', itemOrder: 2 },
  ],
  preview: false,
  quotationFootnoteId: null,
  chineseName: '',
  englishName: '',
  projectChineseName: '',
  projectEnglishName: '',
};

export const QUOTATION_FORM_SCHEMA = Yup.object().shape({
  quotationRef: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(20, '最多20個字元')
    .matches(/^(PES|SB)-Q-\d{5}(R\d{2})?$/, '文件編號格式有誤')
    .required('必填項目')
    .test('check-from-company', '所屬公司有誤', (value, context) => {
      const { fromCompany } = context.parent;
      if (fromCompany && !value.includes(fromCompany)) {
        return false;
      }
      return true;
    }),
  date: Yup.date().nullable().required('請選擇日期'),
  remarks: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  reason: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  customerRef: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元')
    .matches(/^([A-Z])(\d{3})$|^([A-Z])(\d{3})-(\d{3})$/, '客戶編號格式有誤')
    .required('請選擇項目'),
  contactId: Yup.number().nullable().required('請選擇一位聯絡人'),
  contactPhone: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(9, '最多9個字元'),
  contactMobile: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(9, '最多9個字元'),
  QuotationItems: Yup.array().of(
    Yup.object().shape({
      itemName: Yup.string().nullable(),
      quantity: Yup.string().nullable(),
      unit: Yup.string().nullable(),
      unitPrice: Yup.string().nullable(),
      totalPrice: Yup.string().nullable(),
      productRef: Yup.string().nullable(),
      productId: Yup.number().nullable(),
    })
  ),
});

export const QUOTATION_STATUS_OPTIONS: OptionItems[] = [
  { label: '草稿', value: '草稿' },
  { label: '已發佈', value: '已發佈' },
  { label: '已拒絕', value: '已拒絕' },
  { label: '已接受', value: '已接受' },
  { label: '所有狀態', value: '' },
];

export const EMPTY_FORM_QUOTATION_ITEM_DATA: FormQuotationItemData = {
  itemName: null,
  quantity: null,
  unit: null,
  unitPrice: null,
  totalPrice: null,
  productRef: null,
  productId: null,
  isDescription: false,
};

import moment from 'moment';
import * as Yup from 'yup';

import { BreadcrumbItem } from 'app/components/common/Breadcrumb';
import { CLIENT_NAME } from 'app/config';
import { OptionItems, FormInvoiceData, FormInvoiceItemData } from 'app/models';

import { ROUTES } from './routes';

export const NEW_INVOICE_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '發票列表',
    href: ROUTES.INVOICES,
  },
  {
    active: true,
    title: '新增發票',
    href: '#',
  },
];

export const EDIT_INVOICE_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '發票列表',
    href: ROUTES.INVOICES,
  },
  {
    active: true,
    title: '編輯發票',
    href: '#',
  },
];

export const PREVIEW_INVOICE_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '發票列表',
    href: ROUTES.INVOICES,
  },
  {
    active: true,
    title: '預覽發票',
    href: '#',
  },
];

export const INVOICE_FORM_ITEM: FormInvoiceData = {
  invoiceRef: '',
  fromCompany: CLIENT_NAME,
  documentDate: moment().toDate(),
  dueDate: moment().toDate(),
  // remarks: ['', ''],
  category: '',
  remarks1: '',
  remarks2: '',
  status: '草稿',
  reason: '',
  paymentDate: null,
  customerId: null,
  customerRef: '',
  customerName: '',
  projectName: '',
  city: '',
  district: '',
  street: '',
  building: '',
  contactId: null,
  contactName: '',
  contactDepartment: '',
  contactPhone: '',
  contactMobile: '',
  contactFax: '',
  contactEmail: '',
  InvoiceItems: [],
  preview: false,
  chineseName: '',
  englishName: '',
  projectChineseName: '',
  projectEnglishName: '',
  billingAgreement: '',
};

export const INVOICE_FORM_SCHEMA = Yup.object().shape({
  invoiceRef: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(20, '最多20個字元')
    .matches(/^(PES|SB)-I-\d{5}$/, '文件編號格式有誤')
    .required('必填項目')
    .test('check-from-company', '所屬公司有誤', (value, context) => {
      const { fromCompany } = context.parent;
      if (fromCompany && !value.includes(fromCompany)) {
        return false;
      }
      return true;
    }),
  documentDate: Yup.date().nullable().required('請選擇日期'),
  category: Yup.string()
    .min(1, '最少一個字元')
    .max(20, '最多20個字元')
    .required('必填項目'),
  dueDate: Yup.date()
    .nullable()
    .required('請選擇日期')
    .test('compare-due-date', '到期日不可早於文件日期', (value, context) => {
      if (value && context?.parent?.documentDate) {
        const dueDate = moment(value);
        const docDate = moment(context?.parent?.documentDate);
        return dueDate.isAfter(docDate) || dueDate.isSame(docDate, 'date');
      }
      return true;
    }),
  remarks1: Yup.string().max(200, '最多200個字元'),
  remarks2: Yup.string().max(200, '最多200個字元'),
  reason: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
  customerRef: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元')
    .matches(/^([A-Z])(\d{3})$|^([A-Z])(\d{3})-(\d{3})$/, '客戶編號格式有誤')
    .required('請選擇項目'),
  contactId: Yup.number().nullable().required('請選擇一位聯絡人'),
  contactPhone: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(9, '最多9個字元'),
  contactMobile: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(9, '最多9個字元'),
});

export const INVOICE_STATUS_OPTIONS: OptionItems[] = [
  { label: '草稿', value: '草稿' },
  { label: '已發佈', value: '已發佈' },
  { label: '已收款', value: '已收款' },
  { label: '全部狀態', value: '' },
];

export const EMPTY_FORM_INVOICE_ITEM_DATA: FormInvoiceItemData = {
  itemName: null,
  quantity: null,
  unit: null,
  unitPrice: null,
  totalPrice: null,
  productRef: null,
  productId: null,
  isDescription: false,
  jobDate: null,
  driverId: null,
  driverRef: null,
  workChit: null,
  priceTableItemId: null,
};

export const INVOICE_REASON_SCHEMA = Yup.object().shape({
  reason: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元'),
});

export const INVOICE_CALCULATOR_UNITS: {
  [unit: string]: string[];
} = {
  month: ['/month', '/mth', '/unit/mth'],
};

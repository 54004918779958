import { UserRole } from 'app/models';


export const ALL_PERMISSIONS = [
  UserRole.admin,
  UserRole.sales,
  UserRole.order,
  UserRole.account,
  UserRole.warehouse,
];

export const QUOTATION_PAGE_PERMISSIONS = [
  UserRole.admin,
  UserRole.sales,
  UserRole.order,
  UserRole.account,
];

export const CUSTOMER_PAGES_ACCESS_PERMISSIONS = [
  UserRole.admin,
  UserRole.sales,
  UserRole.order,
  UserRole.account,
  UserRole.warehouse,
];

export const CUSTOMER_PAGES_WRITE_PERMISSIONS = [
  UserRole.admin,
  UserRole.sales,
  UserRole.order,
  UserRole.account,
];

export const PRODUCT_PAGES_ACCESS_PERMISSIONS = [
  UserRole.admin,
  UserRole.sales,
  UserRole.order,
  UserRole.warehouse,
];

export const INVOICE_PAGES_ACCESS_PERMISSIONS = [
  UserRole.admin,
  UserRole.order,
  UserRole.account,
  UserRole.warehouse,
  UserRole.sales,
];

export const SUPER_ADMIN_ONLY = [
  UserRole.admin,
];

export const STAFF_PAGES_ACCESS_PERMISSION = [
  UserRole.admin,
  UserRole.order,
  UserRole.warehouse,
];

export const USER_PAGES_ACCESS_PERMISSIONS = [
  UserRole.admin,
];

export const TEMPLATE_PAGES_ACCESS_PERMISSIONS = [
  UserRole.admin,
  UserRole.sales,
];

export const RECEIPT_PAGES_ACCESS_PERMISSIONS = [
  UserRole.admin,
  UserRole.account,
];

export const REPORT_PAGES_ACCESS_PERMISSIONS = [
  UserRole.admin,
  UserRole.order,
  UserRole.account,
  UserRole.warehouse,
];

export const DELETE_PERMISSION = [
  UserRole.admin,
];

export const RESET_STATUS_PERMISSION = [
  UserRole.admin,
  UserRole.account,
];

import {
  Customer,
  CustomerData,
  CustomerPaymentTerm,
  FormType,
  FormattedCustomer,
  Invoice,
  Quotation,
} from 'app/models';
import { QuotationOrInvoice } from 'app/pages/CustomerPage/CustomerDetailPage/CustomerQuotationOrInvoiceTableColumns';

interface FormContact {
  name: string;
  department: string;
  phone: string;
  fax: string;
  email: string;
  isMainContact: boolean;
  contactId?: number;
  mobile: string | null;
}

export const formatCustomerAddress = (
  district: string | null,
  street: string | null,
  building: string | null
) => {
  if (!district && !street && !building) {
    return null;
  } else {
    return [building ?? '', street ?? '', district ?? ''].join(' ');
  }
};

export const formatCustomerFormData = (
  customer: Customer,
  type: FormType
): CustomerData => {
  const tempFormData: CustomerData = {
    customerRef: customer.customerRef,
    customerName: customer.customerName,
    customerChiName: customer.customerChiName,
    fromCompany: customer.fromCompany,
    projectName: customer.projectName,
    projectChiName: customer.projectChiName,
    city: customer.city,
    district: customer.district,
    street: customer.street,
    building: customer.building,
    phone: customer.phone,
    fax: customer.fax,
    remarks: customer.remarks,
    remarks1: customer.remarks1 || customer.remarks?.split('\n')?.[0] || '-',
    remarks2: customer.remarks2 || customer.remarks?.split('\n')?.[1] || '-',
    paymentTerm: customer.paymentTerm,
    paymentTermValue: customer.paymentTermValue,
    billingAgreement: customer.billingAgreement,
    Contacts: customer.Contacts
      ? customer.Contacts.map((contact) => {
          const tempContact: FormContact = {
            name: contact.name,
            department: contact.department,
            phone: contact.phone,
            fax: contact.fax,
            email: contact.email,
            isMainContact: contact.isMainContact,
            mobile: contact.mobile || '',
          };
          if (type === 'edit') {
            tempContact.contactId = contact.contactId;
          }
          return tempContact;
        })
      : [],
  };

  return tempFormData;
};

export const formatCustomerListData: (
  customer: Customer
) => FormattedCustomer = (customer: Customer) => {
  const {
    customerId,
    customerRef,
    customerName,
    customerChiName,
    fromCompany,
    projectName,
    projectChiName,
    phone,
    fax,
    remarks,
    userId,
    Contacts,
    User,
    createdAt,
    deletedAt,
    updatedAt,
    quotationAcceptedCount,
    quotationInProgressCount,
    remarks1,
    remarks2
  } = customer;
  return {
    customerId,
    customerRef,
    customerName,
    customerChiName,
    fromCompany,
    projectName: projectName || null,
    projectChiName,
    phone,
    fax,
    remarks: remarks || '',
    userId,
    mainContact: Contacts.find((contact) => contact.isMainContact) ?? null,
    User,
    createdAt,
    deletedAt,
    updatedAt,
    remarks1,
    remarks2,
    address: formatCustomerAddress(
      customer.district,
      customer.street,
      customer.building
    ),
    quotationStatus: {
      quotationAcceptedCount,
      quotationInProgressCount,
    },
  };
};

export const formatQuotationOrInvoiceTableData = (
  data: Quotation | Invoice
) => {
  const tempData: QuotationOrInvoice = {
    quotationId: 'quotationId' in data ? data.quotationId : undefined,
    invoiceId: 'invoiceId' in data ? data.invoiceId : undefined,
    documentRef: 'quotationRef' in data ? data.quotationRef : data.invoiceRef,
    mainContactName: data.Contact?.name || '',
    phone: data.contactPhone,
    fax: data.contactFax,
    status: data.status,
  };
  return tempData;
};

export const isValidCustomerRef = (refStr: string): boolean => {
  const regex = /^([A-Z])(\d{3})$|^([A-Z])(\d{3})-(\d{3})$/;
  const match = regex.exec(refStr);
  return match !== null;
};

export const getCustomerRefPrefix = (customerRef: string) => {
  if (/^.+-.*$/.test(customerRef)) {
    return customerRef.split('-')[0];
  } else {
    return customerRef;
  }
};

export const translatePaymentTerms = (term: CustomerPaymentTerm) => {
  switch (term) {
    case CustomerPaymentTerm.MONTHS_NUMBER:
      return 'No. of Months';
    case CustomerPaymentTerm.DAYS_NUMBER:
      return 'No. of Days';
    case CustomerPaymentTerm.MONTH_END_WITH_MONTHS_NUMBER:
      return 'Month End + No. of Months';
    case CustomerPaymentTerm.MONTH_END_WITH_DAYS_NUMBER:
      return 'Month End + No. of Days';
    default:
      return '-';
  }
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from 'app/models';

interface AuthState {
  profile: User | null;
}

const initialState = {
  profile: null,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<User | null>) {
      state.profile = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  setProfile,
} = authSlice.actions;
export default authSlice.reducer;

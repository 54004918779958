export const API_URL =
  process.env.REACT_APP_API_URL || 'https://pes-app-dev.appicidea.com';
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PAGE_SIZE = 10;
export const TOAST_TIME = 5000;
export const APP_NAME = `${process.env.REACT_APP_CLIENT_NAME} Admin Web`;
export const IMAGE_SIZE = 1024 * 1024 * 10; // 10Mb
export const PDF_VIEWER_URL =
  process.env.REACT_PDF_VIEWER_URL ||
  'https://static.appicidea.com/public/pdf/web/viewer.html';

// versioning
export const VERSION_NUMBER = process.env.REACT_APP_VERSION || '';
export const CURRENT_ENV = process.env.REACT_APP_ENV || 'Dev';

// client
export const CLIENT_NAME = process.env.REACT_APP_CLIENT_NAME || '';

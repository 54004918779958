import { CLIENT_NAME } from 'app/config';

import { LoadingBubbles } from './LoadingBubbles';

export const Preloader = () => {
  return (
    <div className="z-[999] fixed top-0 right-0 w-[100vw] h-[100dvh] bg-[rgba(255,255,255,0.75)]  flex flex-col items-center justify-center">
      <LoadingBubbles
        size={350}
        color={CLIENT_NAME === 'SB' ? '#FF6600' : '#1B953E'}
      />
      <div className="text-[22px] font-medium text-text-green mt-[-80px]">
        加載資料中...請稍候
      </div>
    </div>
  );
};

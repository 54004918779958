import * as Yup from 'yup';

import { BreadcrumbItem } from 'app/components/common/Breadcrumb';
import {
  CreateTemplateFootnoteItem,
  CreateTemplateForm,
  FormTemplateProductItem,
} from 'app/models';

import { ROUTES } from './routes';

export const NEW_TEMPLATE_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '範本',
    href: ROUTES.TEMPLATES,
  },
  {
    active: true,
    title: '新增範本',
    href: '#',
  },
];

export const EDIT_TEMPLATE_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '範本',
    href: ROUTES.TEMPLATES,
  },
  {
    active: true,
    title: '編輯範本',
    href: '#',
  },
];

export const EMPTY_FORM_TEMPLATE_PRODUCT_ITEM: FormTemplateProductItem = {
  productId: null,
  productRef: null,
  type: null,
  itemName: null,
  unit: null,
  unitPrice: null,
  isDescription: false,
};

export const EMPTY_FORM_TEMPLATE_FOOTNOTE_ITEM: Omit<
  CreateTemplateFootnoteItem,
  'itemOrder'
> = {
  content: null,
};

export const TEMPLATE_FORM: CreateTemplateForm = {
  templateRef: '',
  name: '',
  TemplateFootnoteItems: [
    { ...EMPTY_FORM_TEMPLATE_FOOTNOTE_ITEM, itemOrder: 0 },
    { ...EMPTY_FORM_TEMPLATE_FOOTNOTE_ITEM, itemOrder: 1 },
    { ...EMPTY_FORM_TEMPLATE_FOOTNOTE_ITEM, itemOrder: 2 },
  ],
  TemplateProductItems: [],
};

export const TEMPLATE_FORM_SCHEMA = Yup.object().shape({
  templateRef: Yup.string()
    .min(1, '最少一個字元')
    .max(20, '最多20個字元')
    .required('必填項目'),
  name: Yup.string().max(200, '最多200個字元'),
});

import moment from 'moment';

import {
  FormQuotationData,
  Quotation,
  QuotationStatus,
  InvoiceStatus,
  QuotationFootnoteItemData,
} from 'app/models';

import { formatNumber } from './Common';

export const quotationOrInvoiceStatusColor = (
  status: QuotationStatus | InvoiceStatus | string
) => {
  switch (status) {
    case '草稿':
      return 'text-text-content';
    case '已發佈':
      return 'text-text-yellow';
    case '已拒絕':
      return 'text-text-red';
    case '已接受':
      return 'text-text-green';
    case '已收款':
      return 'text-text-green';
    default:
      return 'text-text-content';
  }
};

export const formatQuotationFormData = (data: Quotation): FormQuotationData => {
  const form: FormQuotationData = {
    quotationRef: data.quotationRef,
    fromCompany: data.fromCompany,
    date: moment(data.date, 'YYYY-MM-DD').toDate(),
    remarks: data.remarks,
    status: data.status,
    reason: data.reason,
    customerId: data.customerId,
    customerRef: data.customerRef,
    customerName: data.customerName,
    projectName: data.projectName,
    district: data.district,
    street: data.street,
    building: data.building,
    city: data.city,
    contactId: data.contactId,
    contactName: data.contactName,
    contactDepartment: data.contactDepartment,
    contactPhone: data.contactPhone,
    contactMobile: data.contactMobile,
    contactFax: data.contactFax,
    contactEmail: data.contactEmail,
    quotationFootnoteId: data.quotationFootnoteId,
    QuotationItems: data.QuotationItems.map((item) => ({
      itemName: item.itemName,
      quantity: formatNumber(item.quantity || '', true) || null,
      unit: item.unit,
      unitPrice: formatNumber(item.unitPrice || '') || null,
      totalPrice: formatNumber(item.totalPrice || '') || null,
      productId: item.productId,
      productRef: item.productRef,
      isDescription: item.productId ? false : true,
      Product: item.Product,
    })),
    QuotationFootnoteItems: (() => {
      const tempItemsArray: QuotationFootnoteItemData[] = [];
      for (let i = 0; i < 3; i++) {
        tempItemsArray.push(
          data.QuotationFootnoteItems.find((item) => item.itemOrder === i) || {
            content: '',
            quotationId: data.quotationId,
            itemOrder: i,
          }
        );
      }
      return tempItemsArray;
    })(),
    preview: false,
    chineseName: data.Customer?.customerChiName || '',
    englishName: data.customerName || '',
    projectChineseName: data.Customer?.projectChiName || '',
    projectEnglishName: data.projectName || '',
  };
  return form;
};

export const isValidQuotationRef = (refStr: string): boolean => {
  const regex = /^(PES|SB)-Q-\d{5}(R\d{2})?$/;
  const match = regex.exec(refStr);
  return match !== null;
};

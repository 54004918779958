import { Link, useLocation } from 'react-router-dom';

import { User, UserRole } from 'app/models';

import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu';

export interface HeaderMenuItem {
  subMenu?: HeaderMenuItem[];
  title: string;
  href: string;
  permissions?: UserRole[];
}

interface HeaderMenuProps {
  headerMenuContent: HeaderMenuItem[];
  profile: User;
}

export const HeaderMenu = ({ headerMenuContent, profile }: HeaderMenuProps) => {
  const location = useLocation();
  return (
    <div className="flex items-center gap-[36px]">
      {headerMenuContent.map((menuContent, index) => {
        if (menuContent.permissions && !menuContent.permissions.includes(profile.role)) {
          return null;
        }

        if (menuContent?.subMenu) {
          const subMenuContent: DropdownMenuItem[] = menuContent?.subMenu.map(
            (subMenuItem) => {
              return { title: subMenuItem.title, href: subMenuItem.href };
            }
          );
          return (
            <DropdownMenu
              key={index}
              title={menuContent.title}
              menuItems={subMenuContent}
            />
          );
        } else {
          return (
            <Link
              className={`whitespace-nowrap text-[14px] ${'/'+location.pathname.split('/')[1] === menuContent.href ? 'text-text-green font-semibold' :'text-text-content font-normal'}`}
              to={menuContent.href}
              key={index}
            >
              {menuContent.title}
            </Link>
          );
        }
      })}
    </div>
  );
};

import React, { useEffect } from 'react';

import { RootState } from 'store';

import { Preloader } from './components/common/Preloader';
import { Header } from './components/layout/header';
import { PageWrapper } from './components/layout/PageWrapper';
import { useAppSelector } from './hooks/store';

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { profile } = useAppSelector((rootState: RootState) => rootState.auth);
  const { isPreloader } = useAppSelector(
    (rootState: RootState) => rootState.layout
  );

  useEffect(() => {
    // if (isPreloader === true) {
    //   if (document.getElementById('preloader')) {
    //     document.getElementById('preloader')!.style.display = 'block';
    //   }

    //   setTimeout(function () {
    //     if (document.getElementById('preloader')) {
    //       document.getElementById('preloader')!.style.display = 'none';
    //     }
    //   }, 2500);
    // } else {
    //   if (document.getElementById('preloader')) {
    //     document.getElementById('preloader')!.style.display = 'none';
    //   }
    // }

    // Scroll Top to 0
    window.scrollTo(0, 0);
  }, [isPreloader]);

  return (
    <>
      {isPreloader ? (
        <div>
          <Preloader />
        </div>
      ) : null}
      {profile ? <Header profile={profile} /> : null}
      <PageWrapper>{children}</PageWrapper>
    </>
  );
};

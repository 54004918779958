export const ROUTES = {
  HOME: '/',

  // customers
  CUSTOMERS: '/customers',
  CUSTOMERS_NEW: '/customers/new',
  CUSTOMERS_EDIT: '/customers/edit',
  CUSTOMERS_COPY: '/customers/copy',
  CUSTOMERS_DETAIL: '/customers/detail',

  // /customers/detail/${id}/priceTable/(new/copy/edit)
  // `${CUSTOMERS_DETAIL}/${id}${CUSTOMERS_PRICE_TABLE_NEW}`
  CUSTOMERS_PRICE_TABLE: '/priceTable',
  CUSTOMERS_PRICE_TABLE_NEW: '/priceTable/new',
  CUSTOMERS_PRICE_TABLE_EDIT: '/priceTable/edit',
  CUSTOMERS_PRICE_TABLE_COPY: '/priceTable/copy',

  //Quotations
  QUOTATIONS: '/quotations',
  QUOTATIONS_NEW: '/quotations/new',
  QUOTATIONS_EDIT: '/quotations/edit',
  QUOTATIONS_COPY: '/quotations/copy',
  QUOTATIONS_PREVIEW: '/quotations/preview',
  QUOTATIONS_DETAIL: '/quotations/detail',

  // Invoices
  INVOICES: '/invoices',
  INVOICES_NEW: '/invoices/new',
  INVOICES_EDIT: '/invoices/edit',
  INVOICES_COPY: '/invoices/copy',
  INVOICES_PREVIEW: '/invoices/preview',
  INVOICES_DETAIL: '/invoices/detail',

  // products
  PRODUCTS: '/products',
  PRODUCTS_NEW: '/products/new',
  PRODUCTS_EDIT: '/products/edit',
  PRODUCTS_COPY: '/products/copy',
  PRODUCTS_DETAIL: '/products/detail',

  // commissions
  COMMISSIONS: '/commissions',

  // driver
  DRIVERS: '/drivers',

  // users
  USERS: '/users',
  USERS_NEW: '/users/new',
  USERS_EDIT: '/users/edit',

  //templates
  TEMPLATES: '/templates',
  TEMPLATES_NEW: '/templates/new',
  TEMPLATES_EDIT: '/templates/edit',
  TEMPLATES_COPY: '/templates/copy',

  //receipts
  RECEIPTS: '/receipts',
  RECEIPTS_NEW: '/receipts/new',
  RECEIPTS_EDIT: '/receipts/edit',
  RECEIPTS_NEW_WITH_INVOICE: '/receipts/new/new_with_invoice',

  // reports
  REPORTS: '/reports',

  CHANGE_PASSWORD: '/change-password',
  SIGN_IN_LOG: '/sign-in-log',
  LOGIN: '/login',
  LOGOUT: '/logout',
  NOT_FOUND: '/not-found',
};

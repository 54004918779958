import React from 'react';

import { HEADER_HEIGHT } from 'app/constants';


interface PageWrapperProps {
  children: React.ReactNode;
}

export const PageWrapper = ({ children }: PageWrapperProps) => {
  return (
    <div style={{ marginTop: `${HEADER_HEIGHT}px` }} className="p-[24px]">{children}</div>
  );
};

export interface ListResult<T> {
  count: number;
  hasNext: boolean;
  limit: number;
  offset: number;
  page: number;
  rows: T[];
}

export interface TimeStamp {
  createdAt: string;
  deletedAt?: null | string;
  updatedAt: string;
}

export interface OptionItems {
  value: string | number | boolean;
  label: string;
}

export type FormType = 'new' | 'edit' | 'copy';

export interface Filter<T> {
  limit: number;
  offset?: number;
  page: number;
  desc?: (keyof T | string)[];
  asc?: (keyof T | string)[];
}

export type SortingState = 'asc' | 'desc' | 'default';

export enum DeletedStatus {
  intact = 'Intact',
  deleted = 'Deleted',
  all = 'All',
}

import * as Yup from 'yup';

import { DriverData, DriverStatus } from 'app/models';

export const DRIVER_FORM_DATA: DriverData = {
  driverRef: '',
  name: '',
  phone: '',
  status: DriverStatus.現職,
};

export const DRIVER_FORM_SCHEMA = Yup.object().shape({
  driverRef: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(20, '最多20個字元')
    .required('必填項目'),
  name: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元')
    .required('必填項目'),
  phone: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(9, '最少9個字元')
});


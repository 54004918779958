import { useRoutes } from 'react-router-dom';

import { ProtectedRoute } from 'app/components/route/ProtectedRoutes';
import {
  CUSTOMER_PAGES_ACCESS_PERMISSIONS,
  CUSTOMER_PAGES_WRITE_PERMISSIONS,
  INVOICE_PAGES_ACCESS_PERMISSIONS,
  PRODUCT_PAGES_ACCESS_PERMISSIONS,
  QUOTATION_PAGE_PERMISSIONS,
  RECEIPT_PAGES_ACCESS_PERMISSIONS,
  REPORT_PAGES_ACCESS_PERMISSIONS,
  ROUTES,
  STAFF_PAGES_ACCESS_PERMISSION,
  TEMPLATE_PAGES_ACCESS_PERMISSIONS,
  USER_PAGES_ACCESS_PERMISSIONS,
} from 'app/constants';
import { useAppSelector } from 'app/hooks/store';
import { LoginPage } from 'app/pages/AuthPage';
import { LogoutPage } from 'app/pages/AuthPage/LogoutPage/loadable';
import { ChangePasswordPage } from 'app/pages/ChangePasswordPage/loadable';
import { CommissionListPage } from 'app/pages/CommissionPage';
import {
  CustomerDetailPage,
  CustomerFormPage,
  CustomerListPage,
} from 'app/pages/CustomerPage';
import { CustomerPriceTablePage } from 'app/pages/CustomerPage/CustomerPriceTablePage/loadable';
import { DriverListPage } from 'app/pages/DriverPage';
import { HomePage } from 'app/pages/HomePage/loadable';
import {
  InvoiceFormPage,
  InvoiceListPage,
  InvoiceDetailPage,
  InvoicePreviewPage,
} from 'app/pages/InvoicePage';
import { NotFoundPage } from 'app/pages/NotFoundPage/loadable';
import { ProductFormPage, ProductListPage } from 'app/pages/ProductPage';
import {
  QuotationFormPage,
  QuotationListPage,
  QuotationDetailPage,
  QuotationPreviewPage,
} from 'app/pages/QuotationPage';
import { ReceiptFormPage, ReceiptListPage } from 'app/pages/ReceiptPage';
import { ReportPage } from 'app/pages/ReportPage/loadable';
import { TemplateFormPage, TemplateListPage } from 'app/pages/TemplatePage';
import { UserListPage, UserFormPage } from 'app/pages/UserPage';
import { UserSignInLogDetailPage } from 'app/pages/UserSignInLogDetailPage/loadable';
import { RootState } from 'store';

export const Routes = () => {
  const { profile } = useAppSelector((rootState: RootState) => rootState.auth);
  const element = useRoutes([
    {
      path: ROUTES.HOME,
      element: (
        <ProtectedRoute profile={profile}>
          <HomePage />
        </ProtectedRoute>
      ),
    },

    // customer pages
    {
      path: ROUTES.CUSTOMERS,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={CUSTOMER_PAGES_ACCESS_PERMISSIONS}
        >
          <CustomerListPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.CUSTOMERS_DETAIL}/:customerId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={CUSTOMER_PAGES_ACCESS_PERMISSIONS}
        >
          <CustomerDetailPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.CUSTOMERS}/:type`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={CUSTOMER_PAGES_WRITE_PERMISSIONS}
        >
          <CustomerFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.CUSTOMERS}/:type/:customerId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={CUSTOMER_PAGES_WRITE_PERMISSIONS}
        >
          <CustomerFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.CUSTOMERS_DETAIL}/:customerId${ROUTES.CUSTOMERS_PRICE_TABLE}/:type`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={CUSTOMER_PAGES_ACCESS_PERMISSIONS}
        >
          <CustomerPriceTablePage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.CUSTOMERS_DETAIL}/:customerId${ROUTES.CUSTOMERS_PRICE_TABLE}/:type/:priceTableId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={CUSTOMER_PAGES_ACCESS_PERMISSIONS}
        >
          <CustomerPriceTablePage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.CUSTOMERS_DETAIL}/:customerId${ROUTES.CUSTOMERS_PRICE_TABLE}/:type/quotation/:quotationId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={CUSTOMER_PAGES_WRITE_PERMISSIONS}
        >
          <CustomerPriceTablePage />
        </ProtectedRoute>
      ),
    },

    // product pages
    {
      path: ROUTES.PRODUCTS,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={PRODUCT_PAGES_ACCESS_PERMISSIONS}
        >
          <ProductListPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.PRODUCTS}/:type`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={PRODUCT_PAGES_ACCESS_PERMISSIONS}
        >
          <ProductFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.PRODUCTS}/:type/:productId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={PRODUCT_PAGES_ACCESS_PERMISSIONS}
        >
          <ProductFormPage />
        </ProtectedRoute>
      ),
    },

    // quotation pages
    {
      path: `${ROUTES.QUOTATIONS}`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={QUOTATION_PAGE_PERMISSIONS}
        >
          <QuotationListPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.QUOTATIONS}/:type`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={QUOTATION_PAGE_PERMISSIONS}
        >
          <QuotationFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.QUOTATIONS}/:type/:quotationId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={QUOTATION_PAGE_PERMISSIONS}
        >
          <QuotationFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.QUOTATIONS}/:type/:quotationId/pdf`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={QUOTATION_PAGE_PERMISSIONS}
        >
          <QuotationPreviewPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.QUOTATIONS_DETAIL}/:quotationId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={QUOTATION_PAGE_PERMISSIONS}
        >
          <QuotationDetailPage />
        </ProtectedRoute>
      ),
    },

    // invoice pages
    {
      path: `${ROUTES.INVOICES}`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={INVOICE_PAGES_ACCESS_PERMISSIONS}
        >
          <InvoiceListPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.INVOICES_DETAIL}/:invoiceId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={INVOICE_PAGES_ACCESS_PERMISSIONS}
        >
          <InvoiceDetailPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.INVOICES}/:type`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={INVOICE_PAGES_ACCESS_PERMISSIONS}
        >
          <InvoiceFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.INVOICES}/:type/:invoiceId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={INVOICE_PAGES_ACCESS_PERMISSIONS}
        >
          <InvoiceFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.INVOICES}/:type/:invoiceId/pdf`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={INVOICE_PAGES_ACCESS_PERMISSIONS}
        >
          <InvoicePreviewPage />
        </ProtectedRoute>
      ),
    },

    // commission page
    {
      path: `${ROUTES.COMMISSIONS}`,
      element: (
        <ProtectedRoute profile={profile} permissions={STAFF_PAGES_ACCESS_PERMISSION}>
          <CommissionListPage />
        </ProtectedRoute>
      ),
    },

    // driver list page
    {
      path: `${ROUTES.DRIVERS}`,
      element: (
        <ProtectedRoute profile={profile} permissions={STAFF_PAGES_ACCESS_PERMISSION}>
          <DriverListPage />
        </ProtectedRoute>
      ),
    },

    // user list page
    {
      path: `${ROUTES.USERS}`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={USER_PAGES_ACCESS_PERMISSIONS}
        >
          <UserListPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.USERS}/:type`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={USER_PAGES_ACCESS_PERMISSIONS}
        >
          <UserFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.USERS}/:type/:userId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={USER_PAGES_ACCESS_PERMISSIONS}
        >
          <UserFormPage />
        </ProtectedRoute>
      ),
    },

    // template pages
    {
      path: `${ROUTES.TEMPLATES}`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={TEMPLATE_PAGES_ACCESS_PERMISSIONS}
        >
          <TemplateListPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.TEMPLATES}/:type`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={TEMPLATE_PAGES_ACCESS_PERMISSIONS}
        >
          <TemplateFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.TEMPLATES}/:type/:templateId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={TEMPLATE_PAGES_ACCESS_PERMISSIONS}
        >
          <TemplateFormPage />
        </ProtectedRoute>
      ),
    },

    // receipt pages
    {
      path: `${ROUTES.RECEIPTS}`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={RECEIPT_PAGES_ACCESS_PERMISSIONS}
        >
          <ReceiptListPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.RECEIPTS}/:type`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={RECEIPT_PAGES_ACCESS_PERMISSIONS}
        >
          <ReceiptFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.RECEIPTS}/:type/:receiptId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={RECEIPT_PAGES_ACCESS_PERMISSIONS}
        >
          <ReceiptFormPage />
        </ProtectedRoute>
      ),
    },
    {
      path: `${ROUTES.RECEIPTS_NEW_WITH_INVOICE}/:invoiceId`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={RECEIPT_PAGES_ACCESS_PERMISSIONS}
        >
          <ReceiptFormPage />
        </ProtectedRoute>
      ),
    },

    // report page
    {
      path: `${ROUTES.REPORTS}`,
      element: (
        <ProtectedRoute
          profile={profile}
          permissions={REPORT_PAGES_ACCESS_PERMISSIONS}
        >
          <ReportPage />
        </ProtectedRoute>
      ),
    },

    // user change password page
    {
      path: `${ROUTES.CHANGE_PASSWORD}/:userId`,
      element: (
        <ProtectedRoute profile={profile} permissions={USER_PAGES_ACCESS_PERMISSIONS}>
          <ChangePasswordPage />
        </ProtectedRoute>
      ),
    },

    //user sign in log page
    {
      path: `${ROUTES.SIGN_IN_LOG}/:userId`,
      element: (
        <ProtectedRoute profile={profile} permissions={USER_PAGES_ACCESS_PERMISSIONS}>
          <UserSignInLogDetailPage />
        </ProtectedRoute>
      ),
    },
    { path: '/login', element: <LoginPage /> },
    { path: '/logout', element: <LogoutPage /> },
    { path: '*', element: <NotFoundPage /> },
  ]);
  return element;
};

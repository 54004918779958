import { TimeStamp } from './Common';


export enum DriverStatus {
  現職 = '現職',
  離職 = '離職',
}

export type DriverStatusType = keyof typeof DriverStatus;
export interface DriverData {
  driverRef: string;
  name: string;
  phone: string;
  status: DriverStatus;
}

export interface Driver extends DriverData, TimeStamp {
  driverId: number;
  userId: number | null;
  commissionThisMonth: number;
  commissionLastMonth: number;
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LayoutState {
  isPreloader: boolean;
  debouncingSearchBarName: string | null;
}

const initialState = {
  isPreloader: false,
  debouncingSearchBarName: null,
} as LayoutState;

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changePreloader(state, action: PayloadAction<boolean>) {
      state.isPreloader = action.payload;
    },
    setDebouncingSearchBarName(state, action: PayloadAction<string | null>) {
      state.debouncingSearchBarName = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { changePreloader, setDebouncingSearchBarName } =
  layoutSlice.actions;
export default layoutSlice.reducer;

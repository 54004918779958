import { User, UserStatus } from 'app/models';
import { CreateUserParams } from 'app/services/UserServices';

export const getUserStatusColor = (status: UserStatus | string) => {
  if (status === '已啟用') {
    return 'text-text-green';
  } else if (status === '已停用') {
    return 'text-text-red';
  } else {
    return '';
  }
};

export const formatUserFormData = (user: User): CreateUserParams => {
  const tempFormData: CreateUserParams = {
    userName: user.userName || '',
    password: '',
    role: user.role,
    displayName: user.displayName || '',
  };

  return tempFormData;
};

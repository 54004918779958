import { Link } from 'react-router-dom';

import { DropdownMenu } from 'app/components/DropdownMenu';
import { HeaderMenu } from 'app/components/layout/HeaderMenu';
import { CLIENT_NAME } from 'app/config';
import {
  HEADER_HEIGHT,
  PROFILE_DROPDOWN_ITEMS,
  HEADER_CONTENT,
} from 'app/constants';
import { User } from 'app/models';

import PESLogo from '../../assets/images/pes-logo.png';
import SBLOGO from '../../assets/images/sb_logo.jpg';

export const Header = ({ profile }: { profile: User }) => {
  return (
    <div
      className="fixed top-0 left-0 px-[24px] flex items-center justify-between flex-nowrap w-[100vw] shadow-topBar bg-brand-white z-50"
      style={{ height: `${HEADER_HEIGHT}px` }}
    >
      <Link to="/" className="shrink-0 mr-[60px]">
        {CLIENT_NAME === 'PES' ? (
          <img
            src={PESLogo}
            alt="pes-logo"
            className="h-[44px]"
            style={{ aspectRatio: '132/44' }}
          />
        ) : null}
        {CLIENT_NAME === 'SB' ? (
          <div className="flex items-center">
            <img
              src={SBLOGO}
              alt="sb-logo"
              className="h-[44px]"
              style={{ aspectRatio: '1:1' }}
            />
            <div>
              <div className="font-bold text-[14px] leading-[14px]">新寶環保服務有限公司</div>
              <div className="text-[12px] leading-[12px]">Sunbo Environmental Services Limited</div>
            </div>
          </div>
        ) : null}
      </Link>
      <div className="flex flex-nowrap gap-[60px] items-center">
        <HeaderMenu headerMenuContent={HEADER_CONTENT} profile={profile} />
        <div className="flex flex-nowrap items-center shrink-0">
          <div className="ml-[24px] flex items-center gap-[8px]">
            <DropdownMenu
              title={profile.displayName || profile.userName || ''}
              menuItems={PROFILE_DROPDOWN_ITEMS}
              iconSrc={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

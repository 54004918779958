import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from 'app';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import { persistor, store } from './store';

Sentry.init({
  dsn: 'https://8cfe6711c5c649ba82e02aa24df97362@sentry.appicidea.com/20',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV || 'development',
  release: process.env.REACT_APP_SENTRY_REL || 'default',
  enabled: process.env.NODE_ENV !== 'development',
  beforeSend: (event: any) => {
    if (window?.location?.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.createRoot(MOUNT_NODE).render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </React.StrictMode>
  </Provider>,
);

import moment from 'moment';
import * as Yup from 'yup';

import {
  CreateOnePriceTableFormParams,
  PriceTableFormItem,
} from 'app/models';

export const CUSTOMER_PRICE_TABLE_FORM: CreateOnePriceTableFormParams = {
  startDate: null,
  endDate: null,
  remarks: ['', ''],
  customerId: null,
  customerRef: '',
  customerName: '',
  PriceTableItems: [],
};

export const EMPTY_PRICE_TABLE_FORM_ITEM: PriceTableFormItem = {
  productId: null,
  productRef: null,
  type: null,
  itemName: null,
  unitPrice: null,
  commission: null,
  defaultCommission: null,
  isDescription: true,
};

export const CUSTOMER_PRICE_TABLE_SCHEMA = Yup.object().shape({
  startDate: Yup.string()
    .required('必填項目')
    .test('startDate', '開始日期必須早於結束日期', function (value) {
      const { endDate } = this.parent;
      if (!value || !endDate) {
        return false;
      }

      const startDateStr = value;
      const endDateStr = endDate;

      const startDateMoment = moment(startDateStr);
      const endDateMoment = moment(endDateStr);
      return (
        startDateMoment.isBefore(endDateMoment) &&
        !startDateMoment.isSame(endDateMoment, 'day')
      );
    }),
  endDate: Yup.string().required('必填項目'),
  remarks: Yup.array()
    .of(Yup.string().nullable())
    .test('maxCombinedLength', '最多總共200個字元', (value) => {
      if (!value || value.length === 0) {
        return true;
      }
      const combinedLength = value.reduce((totalLength, string) => {
        if (string) {
          return totalLength + string.length;
        }
        return totalLength;
      }, 0);
      return combinedLength <= 200;
    }),
  // customerId: Yup.number().nullable(),
  customerRef: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元')
    .required('必填項目'),
  customerName: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元')
    .required('必填項目'),
  PriceTableItems: Yup.array().of(
    Yup.object().shape({
      productRef: Yup.string().nullable(),
      type: Yup.string().nullable(),
      itemName: Yup.string().nullable(),
      unitPrice: Yup.string().nullable(),
      commission: Yup.string().nullable(),
      defaultCommission: Yup.string().nullable(),
    })
  ).min(1, '最少一個貨品項目'),
});

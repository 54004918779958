import * as Yup from 'yup';

import { BreadcrumbItem } from 'app/components/common/Breadcrumb';
import { OptionItems, ProductData } from 'app/models';

import { ROUTES } from './routes';

export const NEW_PRODUCT_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '貨品管理 ',
    href: ROUTES.PRODUCTS,
  },
  {
    active: true,
    title: '新增貨品資料',
    href: '#',
  },
];

export const EDIT_PRODUCT_BREADCRUMB: BreadcrumbItem[] = [
  {
    active: false,
    title: '貨品管理 ',
    href: ROUTES.PRODUCTS,
  },
  {
    active: true,
    title: '編輯貨品資料',
    href: '#',
  },
];

export const PRODUCT_FORM_ITEM: ProductData = {
  productRef: '',
  name: '',
  type: '',
  description: '',
  unit: '',
  unitPrice: null,
  remarks: '',
  defaultCommission: null,
};

export const PRODUCT_FROM_SCHEMA = Yup.object().shape({
  productRef: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(20, '最多20個字元')
    .required('必填項目'),
  name: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(500, '最多500個字元')
    .required('必填項目'),
  type: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(200, '最多200個字元')
    .required('必填項目'),
  description: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(500, '最多500個字元'),
  unit: Yup.string().when('productRef', {
    is: (productRef: string) => productRef && productRef.startsWith('*'),
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.required('必填項目'),
  }),
  unitPrice: Yup.number().min(0, '不可少於零').nullable(),
  remarks: Yup.string()
    .min(1, '最少一個字元')
    .nullable()
    .max(500, '最多500個字元'),
  defaultCommission: Yup.number()
    .min(0, '不可少於零')
    .nullable()
    .when('productRef', {
      is: (productRef: string) => productRef && productRef.startsWith('*'),
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required('必填項目'),
    }),
});

export const ALL_CATEGORY_OPTION: OptionItems = {
  value: '',
  label: '全部類別',
};

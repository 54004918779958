import { TimeStamp } from './Common';

export type UserStatus = '已停用' | '已啟用';

export interface EditingUser {
  userId: string;
  userName: string;
}

export enum UserRole {
  admin = 'admin',
  sales = 'sales',
  order = 'order',
  account = 'account',
  warehouse = 'warehouse',
}

export type UserRoleType = keyof typeof UserRole;

export interface UserData {
  userName: string | null;
  role: UserRole;
  status: UserStatus;
  displayName: string | null;
}

export interface User extends UserData, TimeStamp {
  userId: number;
  email: string;
  notificationToken: string | null;
  isReceiveNotification: boolean;
  onceId: string | null;
  uid: string | null;
}

export interface UserLog extends TimeStamp {
  userLogId: number;
  userId: number;
  type: string;
  ip: string;
  device?: string | null;
  location?: string | null;
  isLoginSuccess: number;
}

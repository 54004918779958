import { Receipt, ReceiptItem } from './Receipt';

import {
  Driver,
  Customer,
  CustomerContact,
  TimeStamp,
  EditingUser,
} from '.';

export type InvoiceStatus = '草稿' | '已發佈' | '已收款';

export enum InvoiceStatusOption {
  草稿 = '草稿',
  已發佈 = '已發佈',
  已收款 = '已收款',
}

export interface InvoiceItemData {
  itemOrder: number;
  jobDate: string | null | Date;
  driverId: number | null;
  driverRef: string | null;
  workChit: string | null;
  productId: number | null;
  productRef: string | null;
  itemName: string | null;
  quantity: string | null;
  unit: string | null;
  unitPrice: string | null;
  totalPrice: string | null;
  // paidAmount: string | null;
}

export interface InvoiceItem extends InvoiceItemData, TimeStamp {
  invoiceItemId: number;
  Driver: Driver;
  priceTableItemId?: null | number;
}

export interface InvoiceData {
  invoiceRef: string;
  fromCompany: string;
  documentDate: string | Date;
  dueDate: string | Date;
  remarks?: string | null;
  remarks1?: string | null;
  remarks2?: string | null;
  status: InvoiceStatus;
  reason: string | null;
  paymentDate: string | null | Date;
  customerId: number | null;
  customerRef: string;
  customerName: string | null;
  projectName: string | null;
  city: string | null;
  district: string | null;
  street: string | null;
  building: string | null;
  contactId: number | null;
  contactName: string;
  contactDepartment: string | null;
  contactPhone: string | null;
  contactMobile: string | null;
  contactFax: string | null;
  contactEmail: string | null;
  InvoiceItems: InvoiceItemData[];
  billingAgreement: string | null;
  category: string;
}

export interface Invoice extends InvoiceData, TimeStamp {
  invoiceId: number;
  userId: number | null;
  invoiceReferenceNumber: string | null;
  salesman: string | null;
  paymentTerm: string | null;
  totalQuantity: number | null;
  totalAmount: number | null;
  totalPaidAmount: number | null;
  Customer: Customer;
  Contact: CustomerContact;
  InvoiceItems: InvoiceItem[];
  Receipts: Receipt[];
  ReceiptItems: ReceiptItem[];
  User: EditingUser;
  CreatedByUser: EditingUser | null;
  building: string | null;
  city: string | null;
  district: string | null;
  street: string | null;
}

export interface FormattedInvoice extends Invoice {
  address: string;
}

export interface FormInvoiceItemData
  extends Omit<InvoiceItemData, 'itemOrder' | 'invoiceItemId' | 'paidAmount'> {
  isDescription?: boolean;
  priceTableItemId?: number | null;
}

export interface FormInvoiceData extends Omit<InvoiceData, 'InvoiceItems' | 'remarks'> {
  InvoiceItems: FormInvoiceItemData[];
  preview: boolean;
  chineseName: string;
  englishName: string;
  projectChineseName: string;
  projectEnglishName: string;
  // remarks: string[];
  remarks1: string | null;
  remarks2: string | null;
}

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { APP_NAME, TOAST_TIME } from 'app/config';
import { AxiosInterceptor } from 'app/services';


import 'react-toastify/dist/ReactToastify.css';
import { Routes } from './route/Routes';
import 'app/assets/scss/custom.scss';

export function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet>
          <title>{APP_NAME}</title>
          <meta name="description" content="Admin Panel" />
        </Helmet>
        <AxiosInterceptor>
          <>
            <DndProvider backend={HTML5Backend}>
              <Routes />
            </DndProvider>
          </>
        </AxiosInterceptor>
        <ToastContainer
          position="top-right"
          autoClose={TOAST_TIME}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </HelmetProvider>
  );
}

import { Menu, Transition } from '@headlessui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import ArrowDown from '../assets/icons/arrow-down.svg';

export interface DropdownMenuItem {
  title: string;
  href?: string;
  disabled?: boolean;
  className?: string;
}

interface DropdownMenuProps {
  title: string;
  iconSrc?: string | boolean;
  menuItems: DropdownMenuItem[];
}

export const DropdownMenu = ({
  title,
  menuItems,
  iconSrc,
}: DropdownMenuProps) => {
  return (
    <Menu as="div" className="relative block">
      {({ open }) => (
        <>
          <Menu.Button className="flex flex-nowrap items-center gap-[4px]">
            {iconSrc && typeof iconSrc === 'string' ? (
              <img src={iconSrc} alt="avatar" className="mr-[4px] shrink-0" />
            ) : null}
            {iconSrc && typeof iconSrc === 'boolean' ? (
              <div className="mr-[4px] w-[30px] h-[30px] bg-brand-green flex items-center justify-center rounded-full shrink-0">
                <div className="font-semibold text-[18px] text-text-white">
                  {title ? title.charAt(0).toUpperCase() : ''}
                </div>
              </div>
            ) : null}
            <span className="font-normal text-[14px] text-text-content whitespace-nowrap shrink-0">
              {title}
            </span>
            <img
              src={ArrowDown}
              alt="v"
              className={open ? 'rotate-180 shrink-0' : 'shrink-0'}
            />
          </Menu.Button>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="absolute right-0 top-[5px] flex flex-col w-[200px] shadow-card">
              {menuItems.map((item, index) => (
                <Menu.Item key={index} disabled={item.disabled}>
                  {({ active }) => {
                    if (item.href) {
                      return (
                        <Link
                          className={`${active ? 'bg-bg-green' : 'bg-bg-white'} ${
                            item.className ? item.className : ''
                          } whitespace-nowrap one-line-ellipsis font-normal text-[14px] text-text-content px-[16px] py-[12px]`}
                          to={item.href}
                        >
                          {item.title}
                        </Link>
                      );
                    } else {
                      return (
                        <div
                          className={`${active ? 'bg-bg-green' : 'bg-bg-white'} ${
                            item.className ? item.className : ''
                          } whitespace-nowrap one-line-ellipsis font-normal text-[14px] text-text-content px-[16px] py-[8px]`}
                        >
                          {item.title}
                        </div>
                      );
                    }
                  }}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

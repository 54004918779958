import axios, { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URL } from 'app/config';

const instance = axios.create({
  baseURL: API_URL + '/v1/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 10000,
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
});

const AxiosInterceptor = ({ children }: { children: ReactElement }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse): AxiosResponse => {
      return response;
    };

    const errInterceptor = (error: AxiosError): Promise<AxiosError> => {
      if (error?.response?.status === 401) {
        navigate('/logout');
      } else if (error?.response?.status === 403) {
        navigate('/logout');
      }
      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    return () => instance.interceptors.response.eject(interceptor);
  }, [navigate]);

  return children;
};

export default instance;
export { AxiosInterceptor };

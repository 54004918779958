import { PriceTableStatus } from 'app/models';

export const getPriceTableStatusColor = (status: PriceTableStatus) => {
  switch (status) {
    case '有效':
      return 'text-text-green';
    case '失效':
      return 'text-text-red';
    case '未生效':
      return 'text-text-placeholder';
  }
};

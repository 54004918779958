import React from 'react';
import { Navigate } from 'react-router-dom';

import { MainLayout } from 'app/layout';
import { User, UserRole } from 'app/models';

interface Props {
  profile: User | null;
  children: React.ReactNode;
  permissions?: UserRole[];
}

export const ProtectedRoute = ({ profile, children, permissions }: Props) => {
  if (!profile) {
    return (
      <Navigate to="/login" replace/>
    );
  }

  if (permissions) {
    if (!permissions.includes(profile.role)) {
      return <Navigate to="/not-fount" replace/>;
    }
  }

  return <MainLayout>{children}</MainLayout>;
};
